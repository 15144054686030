<template>
  <div>
    <div v-if="!loading && !dashboard" style="display: flex; margin: 30px; justify-content: space-between;">
      <div>
      <v-switch
        v-model="showWeightedScores"
        label="View weighted scores"
      ></v-switch>
      </div>
      <div><h1>{{ user.name }} - {{ user.hireDate }}</h1></div>
      <div>
        <v-btn
          v-if="user.isAdmin"
          color="#6bc494"
          style="color: white"
          elevation="2"
          @click="toggleEmailModal"
        >
          Create an assessment request
        </v-btn>
      </div>
    </div>
    <div
      style="display: flex; overflow: scroll; margin-top: 40px"
      :style="
        !dashboard && !fullAssessmentMode ? `height: 100vh; width: 100vw;` : ''
      "
    >
      <Loading v-if="loading" />
      <div
        v-else-if="noContent"
        style="
          height: 50%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-conent: space-between;
          width: 100%;
          text-align: center;
        "
      >
        <Loading />
        Oops.. This user doesn't have any assessments to review yet. <br />
        Returning you back from whence you came
      </div>
      <div style="display: flex; flex-wrap: wrap" v-else>
        <div
          v-for="(assessment, index) in assessments"
          :key="index"
          style="margin-left: 20px; margin-bottom: 50px;"
        >
          <div class="assessment-pills">
            <div>
              <v-btn
                class="selected-pill"
                color="#275dad"
                elevation="2"
                style="font-size: 12px !important"
                rounded
                v-html="formattedAssessmentUserAndDate(assessment.assessmentId)"
              ></v-btn>
            </div>
          </div>
          <div style="display: flex; margin-top: 40px" :key="index">
            <div>
              <div class="completed-by-label">
                Assessment by: <br />
                Completed:
              </div>
              <div class="categories">
                <div
                  v-for="category in assessment.categories"
                  :key="category.id"
                >
                  <div class="assessment-category">
                    <b>{{ category.name }}</b>
                  </div>
                  <div
                    v-for="(subCategory, index) in category.subCategories"
                    :key="index"
                  >
                    <div
                      class="assessment-sub-category"
                      :style="`height:${calculateSubCategoryHeight(
                        subCategory
                      )}px`"
                    >
                      {{ subCategory.name }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div>
                <div class="score-details">
                  <div
                    class="assessing-user"
                    v-html="
                      formattedAssessmentUserAndDate(assessment.assessmentId)
                    "
                  ></div>
                  <div class="full-view-icon">
                    <v-icon
                      v-if="!fullAssessmentMode"
                      @click="toggleFullAssessmentMode"
                      style="color: #656565; font-size: 10px"
                      >mdi-loupe</v-icon
                    >
                    <v-icon
                      v-else
                      @click="toggleFullAssessmentMode"
                      style="color: #328dd1; font-size: 10px"
                      >mdi-loupe</v-icon
                    >
                  </div>
                </div>
                <div
                  v-for="category in assessment.categories"
                  :key="category.id"
                >
                  <div class="category-score">
                    <b>{{ getCategoryAverageScore(category) }}</b>
                  </div>
                  <div
                    v-for="(subCategory, index) in category.subCategories"
                    :key="index"
                  >
                    <div
                      v-if="!fullAssessmentMode"
                      class="full-assessment-score"
                      @click="
                        () =>
                          showSubCategoryDetails(
                            subCategory,
                            getSubCategoryAverageScore(subCategory)
                          )
                      "
                      :style="`background: rgba(0, 171, 102, ${
                        backgroundOpacity(subCategory) * 1.5
                      }; height:${calculateSubCategoryHeight(
                        subCategory
                      )}px; align-items: center;`"
                    >
                      <v-icon
                        v-if="hasComments(subCategory)"
                        class="comments-icon"
                        >mdi-message-text</v-icon
                      >

                      <div style="opacity: 1">
                        {{ getSubCategoryAverageScore(subCategory) }}
                      </div>
                    </div>
                    <div
                      v-else
                      v-for="(skill, index) in subCategory.skills"
                      :key="index"
                      class="sub-category-score"
                      :style="`background: rgba(0, 171, 102, ${
                        backgroundOpacity(subCategory) * 1.5
                      };`"
                    >
                      <div>
                        {{ showWeightedScores ? `${skill.selectedSkillLevel * skill.weight}/${skill.weight*6}` : skill.selectedSkillLevel }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <FullAssessment
              v-if="fullAssessmentMode"
              :assessment="assessment"
            />
          </div>
        </div>
        <Modal
          :show="showAssessmentModal"
          :subCategory="modalContent"
          :categoryScore="modalScore"
          @hideModal="hideModal"
          :showWeightedScore="showWeightedScores"
        />
        <SendAssessmentRequestModal
          v-if="!this.loading && user.isAdmin"
          :users="users"
          :show-modal="showRequestModal"
          :isAdmin="true"
          :userId="user.id"
          @toggle-modal="toggleEmailModal"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Modal from "./Modal";
import FullAssessment from "./FullAssessment.vue";
import { getAllUsers } from "../../api/endpoints/user";
import moment from "moment";
import Loading from "./Loading.vue";
import SendAssessmentRequestModal from "./SendAssessmentRequestModal.vue";
import {
  getCompletedAssessmentRequests,
  getExistingAssessmentDetails,
} from "../../api/endpoints/assessment";

export default {
  components: {
    FullAssessment,
    Modal,
    SendAssessmentRequestModal,
    Loading,
  },
  props: {
    usersAssessments: {
      type: Array,
    },
    usersAssessmentDetails: {
      type: Array,
    },
    dashboard: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: true,
      assessmentUser: null,
      user: null,
      showAssessmentModal: false,
      modalContent: null,
      modalScore: null,
      fullAssessmentMode: false,
      assessmentRequests: [],
      assessments: [],
      assessment: null,
      users: null,
      noContent: false,
      showRequestModal: false,
      adminId: null,
      showWeightedScores: false,
    };
  },
  methods: {
    moment: function () {
      return moment();
    },
    async init() {
      if (!this.$route.query.userId && !this.userId) {
        this.loading = false;
        this.noContent = true;

        setTimeout(() => {
          this.$router.push({
            name: "/",
          });
        }, 5000);

        return;
      }

      this.users = await getAllUsers();
      this.userId = this.$route.query.userId;
      this.user = this.users.find((user) => user.id === this.userId);
      this.adminId = this.$route.query.adminId;

      this.assessmentRequests = await getCompletedAssessmentRequests(
        this.userId
      );

      for (const assessment of this.assessmentRequests) {
        if (this.adminId) {
          const assessmentDetail = await getExistingAssessmentDetails(
            assessment.id
          );
          this.assessments.push(assessmentDetail);
        } else {
          if (assessment.assessorId === this.user.id) {
            const assessmentDetail = await getExistingAssessmentDetails(
              assessment.id
            );
            this.assessments.push(assessmentDetail);
          }
        }
      }

      if (this.dashboard) {
        this.assessments = [this.assessments[0]];
      }

      //this is just to get the categories and labels to display
      this.assessment = this.assessments[0];
      console.log('ta', this.assessment)
      this.loading = false;
    },
    showSubCategoryDetails(subCategory, score) {
      this.modalContent = subCategory;
      this.modalScore = score;
      this.showAssessmentModal = true;
    },
    hideModal() {
      this.showAssessmentModal = false;
    },
    // when looking at assessment results you calculate the max * weight.
    getSubCategoryAverageScore(subCategory) {
      let score = 0;
      let maxScore = 0;
      if (this.showWeightedScores) {
        subCategory.skills.forEach((skill) => {
          score = score + skill.weight * skill.selectedSkillLevel;
          maxScore = maxScore + skill.weight * 6;
        });

        return `${score}/${maxScore}`;
      } else {
        let score = 0;
        subCategory.skills.forEach((s) => {
          score = score + s.selectedSkillLevel;
        });
        score = score / subCategory.skills.length;
        return Math.round(score * 10) / 10;
      }
    },
    getCategoryAverageScore(category) {
      if (this.showWeightedScores) {
        let score = 0;
        let maxScore = 0;
        category.subCategories.forEach((subCategory) => {
          subCategory.skills.forEach((skill) => {
            if (subCategory.name === "Delivery") {
              console.log('skill.weight', skill.weight);
              console.log('skill.selectedSkillLevel', skill.selectedSkillLevel);
            }
            score = score + skill.weight * skill.selectedSkillLevel;
            maxScore = maxScore + skill.weight * 6;
          });
        });
        return `${score}/${maxScore}`;
      } else {
        let score = 0;
        category.subCategories.forEach((s) => {
          score = score + this.getSubCategoryAverageScore(s);
        });
        score = score / category.subCategories.length;
        return Math.round(score * 10) / 10;
      }
    },
    backgroundOpacity(subCategory) {
      // if (this.showWeightedScores) {
      // }
      let score = 0;
      subCategory.skills.forEach((s) => {
        score = score + s.selectedSkillLevel;
      });
      score = score / subCategory.skills.length;
      score = Math.round(score * 1) / 1;
      if (score >= 10) {
        return 1;
      } else {
        return `0.${score}`;
      }
    },
    calculateSubCategoryHeight(subCategory) {
      if (this.fullAssessmentMode) {
        return subCategory.skills.length * 80;
      } else {
        return 25;
      }
    },
    toggleFullAssessmentMode() {
      this.fullAssessmentMode = !this.fullAssessmentMode;
    },
    hasComments(subCategory) {
      return subCategory.skills.some((skill) => skill.comment);
    },
    formattedAssessmentUserAndDate(assessmentId) {
      const assessmentRequest = this.assessmentRequests.find((request) => {
        return request.id === assessmentId;
      });

      const user = this.users.find(
        (user) => user.id === assessmentRequest.assessorId
      );

      return `${this.formatUserName(user.name)} <br /> ${moment(
        assessmentRequest.dateCompleted
      ).format("M-D-YY")}`;
    },
    formatUserName(name) {
      var parts = name.match(/^([a-zA-Z])[^\s]*(.*)$/);
      return parts[1] + "." + parts[2];
    },
    toggleEmailModal() {
      this.showRequestModal = !this.showRequestModal;
    },
  },
  created() {
    this.init();
  },
};
</script>
<style scoped>
.assessment-pills {
  display: flex;
  align-items: space-around;
  cursor: pointer;
}
.selected-pill {
  color: white !important;
  height: 30px;
  border-radius: 1.25rem;
  display: flex;
  width: 120px;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-right: 10px;
  cursor: pointer;
  font-size: 14px;
}
.unselected-pill {
  background-color: #fff;
  color: grey;
  border: 1px solid grey;
  height: 30px;
  border-radius: 1.25rem;
  display: flex;
  width: 120px;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-right: 10px;
  font-size: 14px;
}
.completed-by-label {
  font-size: 10px;
  border: 1px solid #d3d3d3;
  width: 200px;
  height: 50px;
  display: flex;
  align-items: center;
  padding-left: 10px;
}
.assessment-category {
  font-size: 16px;
  border: 1px solid #d3d3d3;
  width: 200px;
  height: 30px;
  display: flex;
  align-items: center;
  padding-left: 10px;
}
.assessment-sub-category {
  font-size: 13px;
  border: 1px solid #d3d3d3;
  width: 200px;
  height: 25px;
  display: flex;
  align-items: center;
  padding-left: 10px;
}
.score-details {
  font-size: 10px;
  border: 1px solid #d3d3d3;
  width: 120px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-left: 10px;
}
.assessing-user {
  flex-grow: 1;
  margin-left: 20px;
}
.full-view-icon {
  font-size: 16px;
  margin-right: 8px;
}
.category-score {
  font-size: 20px;
  border: 1px solid #d3d3d3;
  width: 120px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.full-assessment-score {
  font-size: 15px;
  border: 1px solid #d3d3d3;
  height: 30px;
  display: flex;
  justify-content: center;
  background-color: #42b983;
  position: relative;
}
.comments-icon {
  color: #656565;
  position: absolute !important;
  top: 0px;
  right: 0px;
  height: 16px;
  font-size: 16px !important;
}
.sub-category-score {
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #d3d3d3;
}

button {
  font-size: 12px;
}
</style>
