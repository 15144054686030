<template>
  <div style="display: flex">
    <div class="comment-label" style="display: flex; flex-direction: column">
      <div
        style="
          border: 1px solid #d3d3d3;
          width: 250px;
          height: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding-left: 10px;
        "
      >
        Comment
      </div>
      <div v-for="category in assessment.categories" :key="category.id">
        <div style="height: 30px; width: 250px"></div>
        <div
          v-for="subCategory in category.subCategories"
          :key="subCategory.id"
        >
          <div v-for="(skill, index) in subCategory.skills" :key="index">
            <div
              v-if="skill.comment"
              style="
                border: 1px solid #d3d3d3;
                height: 80px;
                width: 250px;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 10px;
                font-size: 12px;
              "
            >
              <div v-if="skill.comment.length < 125">
                {{ truncateString(skill.comment) }}
              </div>
              <div v-else v-b-tooltip.hover :title="skill.comment">
                {{ truncateString(skill.comment) }}
              </div>
            </div>
            <div
              v-else
              style="border: 1px solid #d3d3d3; height: 80px; width: 250px"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <div style="display: flex; flex-direction: column">
      <div class="levels-container" style="display: flex">
        <div
          v-for="(level, index) in assessment.levels"
          :key="index"
          style="
            border: 1px solid #d3d3d3;
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding-left: 10px;
            width: 250px;
          "
        >
          {{ level.title }}
        </div>
      </div>
      <div class="skills-container">
        <div v-for="category in assessment.categories" :key="category.id">
          <div style="height: 30px; width: 250px"></div>
          <div
            v-for="subCategory in category.subCategories"
            :key="subCategory.id"
          >
            <div
              v-for="skill in subCategory.skills"
              :key="skill.description"
              style="display: flex"
            >
              <div
                v-for="(skillLevel, index) in skill.skillLevels"
                :key="index"
              >
                <div
                  v-if="skillLevel"
                  style="
                    border: 1px solid #d3d3d3;
                    height: 80px;
                    width: 250px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 10px;
                    font-size: 12px;
                  "
                  :style="
                    !skillLevel.value
                      ? `display: none`
                      : `background: ${
                          skill.selectedSkillLevel === index
                            ? 'rgba(0, 171, 102)'
                            : 'white'
                        }`
                  "
                >
                  <div v-if="skillLevel.label.length < 125">
                    {{ truncateString(skillLevel.label) }} 
                  </div>
                  <div v-else v-b-tooltip.hover :title="skillLevel.label">
                    {{ truncateString(skillLevel.label) }}
                  </div>
                </div>
                <div
                  v-else
                  style="border: 1px solid #d3d3d3; height: 80px; width: 250px"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    assessment: {
      type: Object,
    },
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    truncateString(str) {
      if (str.length > 125) {
        return str.slice(0, 125) + "...";
      } else {
        return str;
      }
    },
  },
};
</script>
