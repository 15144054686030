<template>
  <div>
    <vModal
      :draggable="true"
      width="50%"
      height="auto"
      @before-close="beforeClose"
      name="modal"
      id="assessment-modal"
    >
      <div v-if="show" style="height: 100%">
        <div
          class="header"
          :style="`background: rgba(0, 171, 102, ${headerBackgroundOpacity}`"
        >
          <div>{{ subCategory.name }}</div>
          <div>{{ categoryScore }}</div>
        </div>
        <div style="padding: 30px">
          <div class="labels">
            <div style="width: 45%">Selected Skill Level</div>
            <div>Comment</div>
          </div>
          <div class="divider" />
          <div style="display: flex; height: 60%">
            <div class="skill-description-container">
              <div
                v-for="(skill, index) in subCategory.skills"
                :key="index"
                class="skill-content"
              >
                <div style="width: 45%">
                  <div style="width: 200px">
                    {{ skillDescription(skill) }}
                  </div>
                </div>
                <div style="width: 45%">
                  {{ skill.comment }}
                </div>
                <div style="width: 10%; text-align: right; font-size: 18px">
                  {{showWeightedScore ? `${skill.selectedSkillLevel * skill.weight}/${skill.weight*6}` : skill.selectedSkillLevel }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </vModal>
  </div>
</template>

<script>
export default {
  props: {
    subCategory: {
      type: Object,
    },
    show: {
      type: Boolean,
    },
    categoryScore: {
      type: Number,
    },
    showWeightedScore: {
      type: Boolean,
    },
  },
  computed: {
    headerBackgroundOpacity() {
      let score = 0;
      this.subCategory.skills.forEach((s) => {
        score = score + s.selectedSkillLevel;
        score = score / this.subCategory.skills.length;
        score = score * 1.5;
      });
      score = Math.round(score * 1) / 1;
      if (score >= 10) {
        return 1;
      } else {
        return `0.${score}`;
      }
    },
  },
  methods: {
    beforeClose() {
      this.$emit("hideModal");
    },
    skillDescription(skill) {
      const description = skill.skillLevels[skill.selectedSkillLevel];
      return description.label;
    },
    skillComment(skill) {
      const description = skill.skillLevels[skill.selectedSkillLevel];
      return description.comment;
    },
    skillScore(skill) {
      const description = skill.skillLevels[skill.selectedSkillLevel];
      return description.selectedSkillLevel;
    },
  },
  watch: {
    show(val) {
      if (val) {
        this.$modal.show("modal");
      } else {
        this.$modal.hide("modal");
      }
    },
  },
};
</script>
<style scoped>
.header {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 25px 55px;
  font-weight: bold;
}
.labels {
  display: flex;
  padding: 5px 25px;
}
.divider {
  height: 1px;
  width: 100%;
  border-bottom: 1px solid #d3d3de;
}
.skill-description-container {
  width: 100%;
  height: 100%;
}
.skill-content {
  height: 45%;
  padding: 25px;
  font-size: 13px;
  display: flex;
  border-bottom: 1px solid #d3d3de;
}
</style>
